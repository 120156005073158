import { RootState } from '../../../store';

export const selectUser = (state: RootState) => state.auth.user;

export const selectClientIP = (state: RootState) => state.auth.clientIP;

export const selectFolderSearchSortTypes = (state: RootState) =>
  state.auth.values?.folderSearchSortTypes;

export const selectGoogleDriveRootFolderName = (state: RootState) =>
  state.auth.values?.googleDriveRootFolderName;

export const selectAvmEnablePdfReport = (state: RootState) =>
  state.auth.values?.avmEnablePdfReport;

export const selectAvmFromCoreLogic = (state: RootState) =>
  state.auth.values?.avmFromCoreLogic;

export const selectAvmDisclaimerTypes = (state: RootState) =>
  state.auth.values?.avmDisclaimerTypes;

export const selectEnableAvm = (state: RootState) =>
  state.auth.values?.enableAvm;
