import { createSlice } from '@reduxjs/toolkit';

import {
  fetchApexProperty,
  fetchApexSuggestions,
  fetchAvmSuggestions,
  fetchFolders,
  generateApexReport,
  generateAvEstimates,
  updateAvEstimates,
} from './async_thunks';
import { PropertySearchState } from './types';

const initialState: PropertySearchState = {
  suggestions: undefined,
  property: undefined,
  propertyId: undefined,
  clientFolders: [],
  destinationFolder: undefined,
  propertyActiveTool: 'apex',
  estimates: undefined,
  estimatesPropertyId: undefined,
};

export const propertySearchSlice = createSlice({
  name: 'property_search',
  initialState,
  reducers: {
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setDestinationFolder: (state, action) => {
      state.destinationFolder = action.payload;
    },
    setPropertyApexReportUrl: (state, action) => {
      state.property.apexReportUrl = action.payload;
    },
    returnToFolderLevel: (state, action) => {
      // Breadcrumb trail, back, & home buttons
      // Move index back to set the current folder & delete other folders to the right
      state.clientFolders = [
        ...state.clientFolders.slice(0, action.payload + 1),
      ];
    },
    resetSuggestions: (state) => {
      state.suggestions = undefined;
    },
    resetProperty: (state) => {
      state.property = undefined;
    },
    setPropertyActiveTool: (state, action) => {
      state.propertyActiveTool = action.payload;
    },
    setEstimatesPropertyId: (state, action) => {
      state.estimatesPropertyId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchApexSuggestions.fulfilled, (state, action) => {
      // Prevent updating the suggestions list if an address is already selected
      // before all fetch requests are completed
      if (!state.propertyId) {
        state.suggestions = action.payload;
      }
    });

    builder.addCase(fetchApexSuggestions.rejected, (state) => {
      state.suggestions = undefined;
    });

    builder.addCase(fetchApexProperty.fulfilled, (state, action) => {
      state.property = action.payload;
    });

    builder.addCase(fetchApexProperty.rejected, (state) => {
      state.property = undefined;
    });

    builder.addCase(fetchFolders.fulfilled, (state, action) => {
      const currentData = state.clientFolders[action.payload.trailIndex] ?? {};

      const newData = {
        ...currentData,
        ...action.payload.data,
      };

      if (state.clientFolders.length - 1 < action.payload.trailIndex) {
        // Insert a new folder into the trail
        state.clientFolders = [...state.clientFolders, ...[newData]];
      } else if (
        // Update the current folder
        state.clientFolders.length - 1 ===
        action.payload.trailIndex
      ) {
        state.clientFolders[action.payload.trailIndex] = newData;
      }
    });

    builder.addCase(fetchFolders.rejected, (state) => {
      state.property = undefined;
    });

    builder.addCase(generateApexReport.fulfilled, (state, action) => {
      state.property = {
        ...state.property,
        clientDetails: {
          ...state.property.clientDetails,
          ...action.payload.formData,
        },
      };
    });

    builder.addCase(fetchAvmSuggestions.fulfilled, (state, action) => {
      // Prevent updating the suggestions list if an address is already selected
      // before all fetch requests are completed
      if (!state.estimatesPropertyId) {
        state.suggestions = action.payload;
      }
    });

    builder.addCase(fetchAvmSuggestions.rejected, (state) => {
      state.suggestions = undefined;
    });

    builder.addCase(generateAvEstimates.fulfilled, (state, action) => {
      state.estimates = action.payload;
    });

    builder.addCase(generateAvEstimates.rejected, (state) => {
      state.estimates = undefined;
    });

    builder.addCase(updateAvEstimates.fulfilled, (state, action) => {
      state.estimates = action.payload;
    });

    builder.addCase(updateAvEstimates.rejected, (state) => {
      state.estimates = undefined;
    });
  },
});

export const {
  resetProperty,
  resetSuggestions,
  setPropertyId,
  setDestinationFolder,
  setPropertyApexReportUrl,
  returnToFolderLevel,
  setPropertyActiveTool,
  setEstimatesPropertyId,
} = propertySearchSlice.actions;

export default propertySearchSlice.reducer;
