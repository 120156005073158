import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
import LogoImage from '../../../foundation/assets/images/logo_teal.svg';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { clearSessionStorage } from '../../../foundation/utils/common';
import env_constants from '../../../internals/env/env_constants.json';
import { logout } from '../../authentication/redux/async_thunks';
import { selectUser } from '../../authentication/redux/selectors';

const { Header: LayoutHeader } = Layout;

const Header = () => {
  const { isMobileViewport } = useViewport();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const handleLogout = async () => {
    const storedUser = localStorage.getItem('_u');
    const parsed = storedUser ? JSON.parse(storedUser) : null;

    const activeUser = user || parsed;

    try {
      if (activeUser) {
        const data: any = {
          sessionId: activeUser.sessionId,
          appId: env_constants.APP_ID,
          userId: activeUser?.userId,
        };

        await dispatch(
          logout({
            token: activeUser?.token,
            data,
          }),
        ).unwrap();

        clearSessionStorage();
        window.close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menu = useMemo(
    () => [
      {
        key: '1',
        icon: <LogoutOutlined style={{ fontSize: 18 }} />,
        onClick: handleLogout,
        label: `Logout`,
      },
    ],
    [],
  );

  const fullName = useMemo(() => {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }

    return 'User';
  }, [user]);

  return (
    <LayoutHeader className="l-layout__header">
      {/* <Logo /> */}
      <div className="l-layout__header-logo">
        <img
          className="l-layout__header-logo-image"
          src={LogoImage}
          alt="Property Automations"
        />
        <div className="l-layout__header-logo-app">Property Automations</div>
      </div>
      <Dropdown menu={{ items: menu }}>
        <div className="l-layout__header-user">
          {!isMobileViewport ? (
            <div style={{ marginRight: 15 }}>{fullName}</div>
          ) : (
            <></>
          )}
          <div style={{ marginRight: 10 }}>
            <Avatar
              src={user?.picture}
              size="large"
              icon={<UserOutlined />}
              style={{ display: 'block' }}
            />
          </div>
          <div style={{ fontSize: 12 }}>
            <DownOutlined />
          </div>
        </div>
      </Dropdown>
    </LayoutHeader>
  );
};

export default Header;
