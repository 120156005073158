import { LoadingOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import query_string from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { selectUser } from '../../authentication/redux/selectors';
import {
  coreLogicAuthentication,
  getCoreLogicUser,
} from '../redux/async_thinks';

const CoreLogicAuth = () => {
  const location = useLocation(); // Get current URL location
  const navigate = useNavigate(); // Hook for programmatic navigation
  const dispatch = useDispatch(); // Redux dispatch function

  const user = useSelector(selectUser); // Get user data from Redux store

  // Parse query parameters from URL, memoizing result for performance
  const queryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  // Function to handle CoreLogic user authorization
  const authorizeCoreLogicUser = async (code: string) => {
    if (user) {
      // Check if user is logged in
      const { token, sessionId, userId } = user; // Extract user details
      try {
        // Dispatch action to authenticate with CoreLogic
        await dispatch(
          coreLogicAuthentication({
            token,
            data: {
              sessionId,
              userId,
              authorizationCode: code, // Code received from query params
            },
          }),
        ).unwrap();
        navigate('/?tab=avm'); // Navigate to AVM tab on success
      } catch (error) {
        // Handle CoreLogic authentication failure

        // Fetch CoreLogic user details
        const results = await dispatch(
          getCoreLogicUser({ token, sessionId }),
        ).unwrap();

        if (results && results.coreLogicClientId) {
          // Extract CoreLogic details
          const { coreLogicClientId, silentLoggedIn, oAuth2Url, redirectUrl } =
            results;

          // If not silently logged in, redirect to CoreLogic OAuth2 URL
          if (silentLoggedIn === false) {
            window.location.href = `${oAuth2Url}?response_type=code&client_id=${coreLogicClientId}&scope=openid&redirect_uri=${redirectUrl}`;
          }
        }
      }
    }
  };

  useEffect(() => {
    // Check for 'code' in query params on component mount
    if (queryString && queryString.code) {
      authorizeCoreLogicUser(queryString.code);
    } else {
      navigate('/'); // Redirect to home if no code is present
    }
  }, [queryString]); // Re-run effect if queryString changes

  // Display loading indicator while authentication is in progress
  return (
    <div>
      <Result
        icon={<LoadingOutlined style={{ color: '#00b2a3' }} />}
        title="Please wait while we validate your access..."
      />
    </div>
  );
};

export default CoreLogicAuth;
