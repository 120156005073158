import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { omit } from 'lodash';

import { endpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

const getAPIOptions = (token: string, clientId: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
      clientid: clientId,
    },
  };
};

export const fetchApexSuggestions: any = createAsyncThunk(
  'property_search/apexSuggestions',
  async (
    apiParams: { token: string; data: any; cancelToken: CancelTokenSource },
    thunkAPI: any,
  ) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Apex/search-suggestion`,
        encryptedData,
        { ...options, cancelToken: apiParams.cancelToken.token },
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.suggestions;
      })
      .catch((error: any) => {
        if (!axios.isCancel(error)) {
          const decryptedData = decryptData(error.response.data);

          return Promise.reject(new Error(decryptedData));
        }
      });

    return result;
  },
);

export const fetchApexProperty: any = createAsyncThunk(
  'property_search/apexProperty',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Apex/search-property`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.property;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const fetchFolders: any = createAsyncThunk(
  'property_search/folders',
  async (
    apiParams: {
      token: string;
      data: any;
      trailIndex: number;
      folderName: string;
      isLoadNextBatch?: boolean;
    },
    thunkAPI: any,
  ) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(
      apiParams.isLoadNextBatch
        ? apiParams.data
        : omit(apiParams.data, ['nextPageToken']),
    );

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Apex/search-client-folders`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);
        const clientFolder = decrypted.clientFolder;

        const {
          nextPageToken,
          parentId,
          files: responseFolders,
        } = clientFolder;

        let folders = responseFolders;

        if (apiParams.isLoadNextBatch) {
          const currentFolders =
            thunkAPI.getState().property_search.clientFolders?.[
              apiParams.trailIndex
            ]?.folders;

          folders = (currentFolders ?? []).concat(responseFolders);
        }

        result = {
          trailIndex: apiParams.trailIndex,
          data: {
            folders: folders,
            nextPageToken: nextPageToken,
            folderId: parentId,
            folderName: apiParams.folderName,
            searchValue: apiParams.data.searchValue ?? undefined,
            orderAsc: apiParams.data.orderAsc,
            sortType: apiParams.data.sortType,
          },
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const generateApexReport: any = createAsyncThunk(
  'property_search/generateReport',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Apex/report`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);
        const { cashAmount, equityAmount, equityInterestRate } = apiParams.data;

        result = {
          apexReportUrl: decrypted.apexReportUrl,
          formData: {
            cashAmount: `$${cashAmount}`,
            equityAmount: `$${equityAmount}`,
            equityInterestRate: `${(equityInterestRate * 100).toFixed(2)}%`,
          },
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const fetchAvmSuggestions: any = createAsyncThunk(
  'property_search/avmSuggestions',
  async (
    apiParams: { token: string; data: any; cancelToken: CancelTokenSource },
    thunkAPI: any,
  ) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Avm/search-suggestion`,
        encryptedData,
        { ...options, cancelToken: apiParams.cancelToken.token },
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.suggestions;
      })
      .catch((error: any) => {
        if (!axios.isCancel(error)) {
          const decryptedData = decryptData(error.response.data);

          return Promise.reject(new Error(decryptedData));
        }
      });

    return result;
  },
);

export const generateAvEstimates: any = createAsyncThunk(
  'property_search/avEstimates',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Avm/generate`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.propertyAvm;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        if (decryptedData?.resCode === 404) {
          notification.warning({
            message: 'Calculation Failed',
            description: decryptedData.resMessage.replace(
              /Property/g,
              'property',
            ),
          });
        }

        return Promise.reject(decryptedData);
      });

    return result;
  },
);

export const updateAvEstimates: any = createAsyncThunk(
  'property_search/updateAvEstimates',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .put(
        `${env_constants.PA_API_BASE_URL}/Avm/update`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.propertyAvm;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const getPdfReport: any = createAsyncThunk(
  'property_search/pdfReport',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Avm/report`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);
