import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  getClientFoldersCurrentTrailIndex,
  selectClientFolders,
} from '../../property_search/redux/selectors';

const FolderBreadCrumbsTrail = ({ handleClick, children }: any) => {
  const clientFolders = useSelector(selectClientFolders);
  const currentTrailIndex = useSelector(getClientFoldersCurrentTrailIndex);

  return (
    <>
      {!!clientFolders?.length && (
        <ul className="l-folder-select__bread-crumbs-trail">
          {children && <li>{children}</li>}
          {clientFolders.map((clientFolder: any, index: number) => {
            const {
              folderId,
              folderName,
              searchValue,
              orderAsc,
              sortType,
              nextPageToken,
            } = clientFolder;

            return (
              <li
                className="l-folder-select__bread-crumbs-trail-item"
                key={folderId}
              >
                <RightOutlined />
                <button
                  className="l-folder-select__bread-crumbs-trail-btn"
                  onClick={() => {
                    if (handleClick && index !== currentTrailIndex) {
                      handleClick({
                        folderId,
                        folderName,
                        index,
                        searchValue,
                        orderAsc,
                        sortType,
                        nextPageToken,
                      });
                    }
                  }}
                >
                  {index === currentTrailIndex ? (
                    <strong>{folderName}</strong>
                  ) : (
                    <>{folderName}</>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default FolderBreadCrumbsTrail;
