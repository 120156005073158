import { RootState } from '../../../store';

export const selectSuggestions = (state: RootState) =>
  state.property_search.suggestions;

export const selectProperty = (state: RootState) =>
  state.property_search.property;

export const selectPropertyId = (state: RootState) =>
  state.property_search.propertyId;

export const selectClientFolders = (state: RootState) =>
  state.property_search.clientFolders;

export const getClientFoldersCurrentTrailIndex = (state: RootState) =>
  state.property_search.clientFolders.length - 1;

export const selectDestinationFolder = (state: RootState) =>
  state.property_search.destinationFolder;

export const selectPropertyActiveTool = (state: RootState) =>
  state.property_search.propertyActiveTool;

export const selectEstimates = (state: RootState) =>
  state.property_search.estimates;

export const selectEstimatesPropertyId = (state: RootState) =>
  state.property_search.estimatesPropertyId;
