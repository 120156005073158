import { Buffer } from 'buffer';
import query_string from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Error from '../../foundation/components/error/Error';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import { RouteType, useRoutes } from '../../foundation/config/routes';
import { clearSessionStorage } from '../../foundation/utils/common';
import envConstants from '../../internals/env/env_constants.json';
import {
  fetchClientData,
  fetchValues,
  refreshToken,
} from '../authentication/redux/async_thunks';
import { selectClientIP, selectUser } from '../authentication/redux/selectors';
import { setClientIP, setUser } from '../authentication/redux/slice';
import PrivateRoute from './private_route/PrivateRoute';
import PublicRoute from './public_route/PublicRoute';

const Startup = () => {
  const { APP_ID } = envConstants;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useRoutes();

  const clientIP = useSelector(selectClientIP);
  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(true);

  const accessQueryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  const renderRoute: any = (item: RouteType) => {
    if (item?.component) {
      return (
        <Route
          key={item.path}
          path={item.path}
          element={
            <PrivateRoute>
              <item.component />
            </PrivateRoute>
          }
        />
      );
    }

    return <></>;
  };

  const privateRoutes = useMemo(() => {
    const items: any[] = [];

    for (const route of routes) {
      items.push(renderRoute(route));
    }
    return items;
  }, [routes]);

  const isRoutePrivate = useMemo(() => {
    const privatePaths = privateRoutes.map((route: any) => {
      return route?.props?.path;
    });

    return (routePath: any) => {
      return privatePaths.includes(routePath);
    };
  }, [privateRoutes]);

  const isCurrentRoutePrivate = isRoutePrivate(location.pathname);

  const errorHandler = (error: Error | string) => {
    console.log(error);

    clearSessionStorage();
    setIsLoading(false);

    navigate('/error');
  };

  const getClientIP: any = async () => {
    try {
      const responseClientIP = await dispatch(fetchClientData()).unwrap();

      dispatch(setClientIP(responseClientIP));
      localStorage.setItem('_c', responseClientIP);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getValues = async () => {
    try {
      if (user) {
        await dispatch(
          fetchValues({
            data: {
              userId: user.userId,
              sessionId: user.sessionId,
            },
            token: user.token,
          }),
        );
      }

      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onboardUser = async () => {
    try {
      const storedUser = localStorage.getItem('_u');

      if (accessQueryString?.appAccess) {
        const buff = Buffer.from(accessQueryString.appAccess, 'base64');
        const text = buff.toString();

        const {
          AppToken,
          RoleId,
          UserId,
          SessionId,
          UserPicture,
          UserFirstName,
          UserLastName,
        } = JSON.parse(text);

        const userObject = {
          sessionId: SessionId,
          userId: UserId,
          expiry: AppToken?.Expiry,
          token: AppToken?.Token,
          firstName: UserFirstName,
          lastName: UserLastName,
          picture: UserPicture,
          roleId: RoleId,
        };

        dispatch(setUser(userObject));
        localStorage.setItem('_u', JSON.stringify(userObject));

        navigate('/');
      } else if (storedUser) {
        const { userId, sessionId } = JSON.parse(storedUser);
        const response = await dispatch(
          refreshToken({
            data: {
              userId: userId,
              sessionId: sessionId,
              appId: APP_ID,
            },
          }),
        ).unwrap();
        localStorage.setItem('_u', JSON.stringify(response));

        // If code is preset in params then it means that the user has been redirected to our app
        // by core logic after silent logic so validate the code.
        if (accessQueryString?.code) {
          navigate(`/core-logic?code=${accessQueryString.code}&tab=avm`);
        }
      } else {
        errorHandler('Invalid onboarding');
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const storedClientIP = localStorage.getItem('_c');

    if (!clientIP) {
      getClientIP();
    } else if (storedClientIP) {
      dispatch(setClientIP(clientIP));
    }
  }, []);

  useEffect(() => {
    if (clientIP) {
      if (!user && isCurrentRoutePrivate) {
        onboardUser();
      } else {
        setIsLoading(false);
      }
    }
  }, [clientIP, isCurrentRoutePrivate]);

  useEffect(() => {
    if (user) {
      getValues();
    }
  }, [user]);

  if (isLoading) {
    return <FullPageLoader message="Verifying access..." />;
  }

  return (
    <Routes>
      <Route
        path="/error"
        element={
          <PublicRoute>
            <Error />
          </PublicRoute>
        }
      />
      {privateRoutes}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Startup;
