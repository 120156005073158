import {
  CloseCircleFilled,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Tabs } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import query_string from 'query-string';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import reactStringReplace from 'react-string-replace';

import Search from '../../foundation/assets/svgs/Search';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import env_constants from '../../internals/env/env_constants.json';
import PropertyDetails from '../apex/property_details/PropertyDetails';
import { selectEnableAvm, selectUser } from '../authentication/redux/selectors';
import AvEstimates from '../avm/estimates/Estimates';
import {
  fetchApexProperty,
  fetchApexSuggestions,
  fetchAvmSuggestions,
  generateAvEstimates,
} from './redux/async_thunks';
import {
  selectEstimatesPropertyId,
  selectProperty,
  selectPropertyActiveTool,
  selectPropertyId,
  selectSuggestions,
} from './redux/selectors';
import {
  resetSuggestions,
  setEstimatesPropertyId,
  setPropertyActiveTool,
  setPropertyId,
} from './redux/slice';

const PropertySearch = () => {
  const { PROPERTY_SEARCH_KEYWORD_MIN_LENGTH: KEYWORD_LENGTH } = env_constants;
  const { isMobileViewport } = useViewport();

  const dispatch = useDispatch();

  const inputRef: any = useRef(null);
  const submitRef: any = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const suggestions = useSelector(selectSuggestions);
  const property = useSelector(selectProperty);
  const propertyId = useSelector(selectPropertyId);
  const estimatesPropertyId = useSelector(selectEstimatesPropertyId);
  const enableAvm = useSelector(selectEnableAvm);

  const propertyActiveTool = useSelector(selectPropertyActiveTool);

  const isApex = propertyActiveTool === 'apex';

  const [searchTerm, setSearchTerm] = useState('');

  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);
  const [isLookupLoading, setIsLookupLoading] = useState(false);
  const [isSuggestionError, setIsSuggestionError] = useState(false);

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const queryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    // Check if there is a 'tab' query parameter in the URL
    if (queryString.tab) {
      // Check if the 'tab' value matches the current active tool
      if (queryString.tab === propertyActiveTool) {
        // If they match, do nothing (no need to update state)
        return;
      } else {
        // If they don't match, dispatch an action to update the active tool in the Redux store
        dispatch(setPropertyActiveTool(queryString.tab));
      }
    }
  }, [queryString, propertyActiveTool]);

  const apexSuggestions = async (keyword: string) => {
    setIsSuggestionLoading(true);
    setIsSuggestionError(false);

    if (user) {
      try {
        cancelTokenSource.current = axios.CancelToken.source();
        const { token, userId, sessionId } = user;

        const response = await dispatch(
          fetchApexSuggestions({
            token: token,
            data: {
              userId: userId,
              sessionId: sessionId,
              keyword: keyword,
            },
            cancelToken: cancelTokenSource.current,
          }),
        ).unwrap();

        if (response) {
          setIsSuggestionLoading(false);
        }
      } catch (e) {
        setIsSuggestionError(true);
        setIsSuggestionLoading(false);
      }
    }
  };

  const avmSuggestions = async (keyword: string) => {
    setIsSuggestionLoading(true);
    setIsSuggestionError(false);

    if (user) {
      try {
        cancelTokenSource.current = axios.CancelToken.source();
        const { token, userId, sessionId } = user;

        const response = await dispatch(
          fetchAvmSuggestions({
            token: token,
            data: {
              userId: userId,
              sessionId: sessionId,
              keyword: keyword,
            },
            cancelToken: cancelTokenSource.current,
          }),
        ).unwrap();

        if (response) {
          setIsSuggestionLoading(false);
        }
      } catch (e) {
        setIsSuggestionError(true);
        setIsSuggestionLoading(false);
      }
    }
  };

  const getApexProperty = async () => {
    if (user && !isLookupLoading) {
      setIsLookupLoading(true);

      const { token, userId, sessionId } = user;

      await dispatch(
        fetchApexProperty({
          token: token,
          data: {
            userId: userId,
            sessionId: sessionId,
            propertyId: propertyId,
          },
        }),
      );

      setIsLookupLoading(false);
    }
  };

  const getAvmProperty = async () => {
    if (user && !isLookupLoading) {
      setIsLookupLoading(true);

      const { token, userId, sessionId } = user;

      await dispatch(
        generateAvEstimates({
          token: token,
          data: {
            userId: userId,
            sessionId: sessionId,
            propertyId: estimatesPropertyId,
          },
        }),
      );

      setIsLookupLoading(false);
    }
  };

  const resetId = () => {
    if (isApex) {
      dispatch(setPropertyId(undefined));
    } else {
      dispatch(setEstimatesPropertyId(undefined));
    }
  };

  const getId = () => {
    if (isApex) {
      return propertyId;
    }

    return estimatesPropertyId;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchTerm(value);
    dispatch(resetSuggestions());
    resetId();
    setIsSuggestionLoading(false);

    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Request cancelled on keyword change');
    }

    if (value.length >= KEYWORD_LENGTH) {
      if (isApex) {
        apexSuggestions(value);
      } else {
        avmSuggestions(value);
      }
    }
  };

  const handleFocus = () => {
    if (getId() && searchTerm) {
      const cleanAddress = searchTerm
        .replace(/(\b[A-Z]{2,3}\b|\b\d{4}\b)\s*/g, '')
        .replace(/,\s*$/, '')
        .trim();

      setSearchTerm(cleanAddress);
      resetId();

      if (isApex) {
        apexSuggestions(cleanAddress);
      } else {
        avmSuggestions(cleanAddress);
      }
    }
  };

  const handleSuggestionClick = (suggestion: {
    propertyId: string;
    propertyAddress: string;
  }) => {
    setSearchTerm(suggestion.propertyAddress);
    dispatch(resetSuggestions());

    if (isApex) {
      dispatch(setPropertyId(suggestion.propertyId));
    } else {
      dispatch(setEstimatesPropertyId(suggestion.propertyId));
    }
  };

  const handleClear = (e: any) => {
    e.stopPropagation();

    setSearchTerm('');
    resetId();
    dispatch(resetSuggestions());
  };

  const renderLoader = (color?: string) => {
    return (
      <LoadingOutlined
        spin
        style={{ color: color ?? 'white', fontSize: '18px' }}
      />
    );
  };

  useEffect(() => {
    if (inputRef?.current && !searchTerm && !getId()) {
      inputRef.current.focus();
    }

    if (submitRef?.current && getId()) {
      submitRef.current.focus();
    }
  }, [searchTerm, propertyId, estimatesPropertyId]);

  useEffect(() => {
    // Workaround to clear suggestions when search input is emptied
    // before a successful fetch call
    if (searchTerm && searchTerm.length < KEYWORD_LENGTH) {
      dispatch(resetSuggestions());
    }
  }, [searchTerm, suggestions]);

  const renderSubmitButton = () => {
    const btnText = isApex ? 'Lookup Property' : 'Calculate';

    const btnAction = isApex
      ? () => {
          getApexProperty();
        }
      : () => {
          getAvmProperty();
        };

    return (
      <Button
        className="l-property-search__lookup-btn"
        type="primary"
        ref={submitRef}
        onClick={btnAction}
        disabled={!getId()}
      >
        {isLookupLoading ? (
          <>{renderLoader()}</>
        ) : (
          <>{isMobileViewport ? <SearchOutlined /> : btnText}</>
        )}
      </Button>
    );
  };

  const tabItems = useMemo(() => {
    const tabs = [
      {
        label: <h3 className="l-property-search__heading">APEX Report</h3>,
        key: 'apex',
        children: null,
        disabled: false,
      },
      {
        label: <h3 className="l-property-search__heading">AV Estimates</h3>,
        key: 'avm',
        disabled: !enableAvm,
        children: null,
      },
    ];

    return tabs;
  }, [enableAvm]);

  return (
    <div className="l-property-search">
      <Tabs
        defaultActiveKey={propertyActiveTool}
        activeKey={propertyActiveTool}
        centered
        onChange={(tab: string) => {
          dispatch(setPropertyActiveTool(tab));
          setSearchTerm('');
          dispatch(resetSuggestions());
          resetId();
          setIsSuggestionLoading(false);
          // Add tab params
          navigate(`${location.pathname}?tab=${tab}`);
          if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Request cancelled on tab change');
          }
        }}
        items={tabItems}
        className="main-tabs"
      />

      <div className="l-property-search__input-container">
        <Input
          className="l-property-search__input"
          ref={inputRef}
          placeholder="Search Address"
          onChange={handleChange}
          onFocus={handleFocus}
          value={searchTerm}
          prefix={
            <div className="l-property-search__input-prefix">
              {isSuggestionLoading ? (
                <>{renderLoader('#00b2a3')}</>
              ) : (
                <span style={{ opacity: '0.5', display: 'flex' }}>
                  <Search />
                </span>
              )}
            </div>
          }
          suffix={
            <Button
              onClick={handleClear}
              type="link"
              icon={
                searchTerm && (
                  <CloseCircleFilled style={{ color: '#bababa ' }} />
                )
              }
            />
          }
        />
        {renderSubmitButton()}
        {searchTerm?.length >= KEYWORD_LENGTH &&
          !isSuggestionLoading &&
          isSuggestionError &&
          !suggestions?.length &&
          !getId() && (
            <ul className="l-property-search__suggestions">
              <li className="l-property-search__suggestion-item l-property-search__suggestion-item--error">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '17px',
                    marginRight: '25px',
                    opacity: 0.5,
                  }}
                />
                No address found
              </li>
            </ul>
          )}
        {suggestions?.length > 0 && !getId() && (
          <ul className="l-property-search__suggestions">
            {suggestions.map((suggestion: any) => (
              <li
                className="l-property-search__suggestion-item"
                key={suggestion.propertyId}
              >
                <button onClick={() => handleSuggestionClick(suggestion)}>
                  <EnvironmentOutlined
                    style={{
                      fontSize: '17px',
                      marginRight: '17px',
                      opacity: 0.5,
                    }}
                  />
                  <span className="h-item">
                    {reactStringReplace(
                      suggestion.propertyAddress,
                      searchTerm,
                      (match: string | undefined) => {
                        return (
                          <strong key={suggestion.propertyId}>{match}</strong>
                        );
                      },
                    )}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      {isApex && !!property && (
        <PropertyDetails isLookupLoading={isLookupLoading} />
      )}
      {!isApex && <AvEstimates />}
    </div>
  );
};

export default PropertySearch;
