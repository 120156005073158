import { Modal } from 'antd';
import DOMPurify from 'dompurify'; // Import a library for sanitization
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  selectAvmDisclaimerTypes,
  selectUser,
} from '../../authentication/redux/selectors';
import { getCoreLogicDisclaimerContent } from '../redux/async_thinks';
import { DISCLAIMER_MODAL_TYPES } from '../redux/types';
import ConsumerFieldDisclaimer from './ConsumerFieldDisclaimer';
import MainSourceDisclaimer, {
  renderStateDisclaimer,
} from './MainSourceDisclaimer';

type PreviewModalProps = {
  closeHandler: () => void;
  type: DISCLAIMER_MODAL_TYPES;
  stateCode?: string;
};

const DisclaimerModal = ({
  closeHandler,
  type,
  stateCode,
}: PreviewModalProps) => {
  const [isDisclaimerLoading, setDisclaimerLoading] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState('');
  const dispatch = useDispatch();
  const { isMobileViewport } = useViewport();

  const user = useSelector(selectUser);

  const avmDisclaimerTypes = useSelector(selectAvmDisclaimerTypes);

  const modalType = useMemo(() => {
    if (type && avmDisclaimerTypes) {
      for (const avmDisclaimerType of avmDisclaimerTypes) {
        if (avmDisclaimerType.key === type) {
          return avmDisclaimerType.value;
        }
      }
    }
  }, [avmDisclaimerTypes, type]);

  // Function to fetch and process disclaimer data from CoreLogic
  const fetchDisclaimerData = async () => {
    if (user) {
      try {
        setDisclaimerLoading(true);
        const { sessionId, token, userId } = user;

        const reqData: any = {
          sessionId,
          token,
          userId,
          type: modalType,
        };

        if (type === 'State') {
          reqData.stateCode = stateCode;
        }
        const data = await dispatch(
          getCoreLogicDisclaimerContent(reqData),
        ).unwrap();

        // Sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(data.disclaimer);

        setDisclaimerData(sanitizedHtml);
        setDisclaimerLoading(false);
      } catch (error) {
        setDisclaimerLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // Fetch disclaimer data
    fetchDisclaimerData();
  }, [type]);

  return (
    <Modal
      title={'Disclaimer'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={isMobileViewport ? '100%' : '80%'}
      style={{ maxWidth: '700px' }}
      styles={{
        body: {
          padding: '18px 30px 30px',
          height: isMobileViewport ? '100%' : '600px',
          overflowY: 'scroll',
        },
      }}
      className="disclaimer-modal"
    >
      <div style={{ position: 'relative' }}>
        {isDisclaimerLoading && (
          <FullPageLoader classNames="corelogic-disc-op-100" />
        )}

        {type === 'State' && (
          <>
            <MainSourceDisclaimer />
            <p>
              <strong>Australian State Disclaimers</strong>
              <br />
              Where Product Data (or any reports supplied by CoreLogic AsiaPac)
              have been compiled with data supplied under licence from an
              Australian State government supplier, the following State
              disclaimers apply and must be displayed in conjunction with
              CoreLogic data disclaimers:
              <br />
              <br />
              {stateCode && renderStateDisclaimer(stateCode)}
              <div
                className="corelogic-disc-tooltip"
                dangerouslySetInnerHTML={{ __html: disclaimerData }}
              />
            </p>
          </>
        )}

        {type !== 'State' && (
          <>
            <div
              className="corelogic-disc-tooltip"
              dangerouslySetInnerHTML={{ __html: disclaimerData }}
            />
            <br />
            {type === 'Confidence' && <ConsumerFieldDisclaimer />}
          </>
        )}
      </div>
    </Modal>
  );
};

export default DisclaimerModal;
