import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import auth from '../features/authentication/redux/slice';
import property_search from '../features/property_search/redux/slice';

export interface IRootState {
  router: RouterState;
}

export default (history: History) => ({
  router: connectRouter(history),
  auth,
  property_search,
});
