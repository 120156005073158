import { Modal } from 'antd';
import React from 'react';

import FolderSelect from '../FolderSelect';

type FolderSelectModalProps = {
  closeHandler: () => void;
};

const FolderSelectModal = ({ closeHandler }: FolderSelectModalProps) => {
  return (
    <Modal
      title={'Select Folder'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={1000}
      styles={{
        body: {
          padding: '0',
        },
      }}
    >
      <FolderSelect closeHandler={closeHandler} />
    </Modal>
  );
};

export default FolderSelectModal;
