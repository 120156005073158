import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { endpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

const getAPIOptions = (token: string, clientId: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
      clientid: clientId,
    },
  };
};

export const getCoreLogicUser: any = createAsyncThunk(
  'avm/getCoreLogicUser',
  async (apiParams: { token: string; sessionId: string }, thunkAPI: any) => {
    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const response = await endpoint.get(
      `${env_constants.PA_API_BASE_URL}/Avm/core-logic-user`,
      {
        ...options,
      },
    );
    const decrypted = decryptData(response.data);
    return decrypted;
  },
);

export const coreLogicAuthentication: any = createAsyncThunk(
  'avm/coreLogicAuthentication',
  async (
    apiParams: {
      token: string;
      data: { userId: string; authorizationCode: string; sessionId: string };
    },
    thunkAPI: any,
  ) => {
    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    const response = await endpoint.post(
      `${env_constants.PA_API_BASE_URL}/Avm/core-logic-auth`,
      encryptedData,
      {
        ...options,
      },
    );
    const decrypted = decryptData(response.data);

    return decrypted;
  },
);

export const getCoreLogicDisclaimerContent: any = createAsyncThunk(
  'avm/getCoreLogicDisclaimerContent',
  async (
    apiParams: {
      token: string;
      sessionId: string;
      userId: string;
      type: string;
      stateCode?: string;
    },
    thunkAPI: any,
  ) => {
    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    let reqData: any = {
      userId: apiParams.userId,
      sessionId: apiParams.sessionId,
      type: apiParams.type,
    };

    if (apiParams.stateCode) {
      reqData.stateCode = apiParams.stateCode;
    }

    const encryptedData = encryptData(reqData);

    const response = await endpoint.post(
      `${env_constants.PA_API_BASE_URL}/Avm/core-logic-disclaimer`,
      encryptedData,
      {
        ...options,
      },
    );
    const decrypted = decryptData(response.data);
    return decrypted;
  },
);
