import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';

export default (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Result
      status="error"
      title="Error"
      subTitle="Oops, something went wrong. Please contact your system admin or try again later."
      extra={<Button onClick={() => navigate('/')}>Back Home</Button>}
    />
  );
};
