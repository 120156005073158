import { Buffer } from 'buffer';
import CryptoES from 'crypto-es';

import envConstant from '../../internals/env/env_constants.json';

const BASE_ENCODING_FORMAT = 'base64';
const STRING_FORMAT = 'utf8';

const MODE = { mode: CryptoES.mode.CBC };

const APPSTORE_OPTIONS = {
  format: CryptoES.format.Hex,
  padding: CryptoES.pad.Pkcs7,
};

export const getUTF8KeyAndIV = (keyIV?: string[]) => {
  const decodedKey = Buffer.from(
    keyIV ? keyIV[0] : envConstant.DEFAULT_PASS_KEY,
    BASE_ENCODING_FORMAT,
  ).toString(STRING_FORMAT);

  const decodedIV = Buffer.from(
    keyIV ? keyIV[1] : envConstant.DEFAULT_PASS_IV,
    BASE_ENCODING_FORMAT,
  ).toString(STRING_FORMAT);

  return [decodedKey, decodedIV];
};

export const encryptData = (data: any, keyIV?: string[]) => {
  let encrypted;
  let UTF8KeyIV;

  const fixedData = typeof data === 'string' ? data : JSON.stringify(data);

  if (keyIV) {
    UTF8KeyIV = getUTF8KeyAndIV(keyIV);

    encrypted = CryptoES.AES.encrypt(
      fixedData,
      CryptoES.enc.Utf8.parse(UTF8KeyIV[0]),
      {
        iv: CryptoES.enc.Utf8.parse(UTF8KeyIV[1]),
        ...MODE,
        ...APPSTORE_OPTIONS,
      },
    );
  } else {
    UTF8KeyIV = getUTF8KeyAndIV();

    encrypted = CryptoES.AES.encrypt(
      fixedData,
      CryptoES.enc.Utf8.parse(UTF8KeyIV[0]),
      {
        iv: CryptoES.enc.Utf8.parse(UTF8KeyIV[1]),
        ...MODE,
      },
    );
  }

  return encrypted.toString();
};

export const decryptData = (data: any, keyIV?: string[]) => {
  let decrypted;
  let UTF8KeyIV;

  if (keyIV) {
    UTF8KeyIV = getUTF8KeyAndIV(keyIV);

    decrypted = CryptoES.AES.decrypt(
      data,
      CryptoES.enc.Utf8.parse(UTF8KeyIV[0]),
      {
        iv: CryptoES.enc.Utf8.parse(UTF8KeyIV[1]),
        ...MODE,
        ...APPSTORE_OPTIONS,
      },
    );
  } else {
    UTF8KeyIV = getUTF8KeyAndIV();

    decrypted = CryptoES.AES.decrypt(
      data,
      CryptoES.enc.Utf8.parse(UTF8KeyIV[0]),
      {
        iv: CryptoES.enc.Utf8.parse(UTF8KeyIV[1]),
        ...MODE,
      },
    );
  }

  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const getSessionClientID = (
  clientIP: string,
  sessionId: string,
  appStoreKeyIV?: string[],
) => {
  const userAgent = window.navigator.userAgent;

  return encryptData(
    `${userAgent}/${clientIP}/${sessionId}`,
    appStoreKeyIV ?? undefined,
  );
};
