import {
  ArrowRightOutlined,
  EnvironmentFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Divider, InputNumber, notification, Row } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import query_string from 'query-string';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as Yup from 'yup';

import Bath from '../../../foundation/assets/svgs/Bath';
import Bed from '../../../foundation/assets/svgs/Bed';
import Car from '../../../foundation/assets/svgs/Car';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import {
  selectAvmEnablePdfReport,
  selectAvmFromCoreLogic,
  selectUser,
} from '../../authentication/redux/selectors';
import {
  getPdfReport,
  updateAvEstimates,
} from '../../property_search/redux/async_thunks';
import { selectEstimates } from '../../property_search/redux/selectors';
import DisclaimerModal from '../disclaimer_modal/DisclaimerModal';
import { getCoreLogicUser } from '../redux/async_thinks';
import { DISCLAIMER_MODAL_TYPES } from '../redux/types';

const validationSchema = Yup.object().shape({
  estimatedPrice: Yup.number()
    .required('Estimated Price is required')
    .positive('Estimated Price must be a positive number')
    .typeError('Invalid Estimated Price amount'),
  estimatedPriceLowerRange: Yup.number()
    .required('Estimated Price Lower Range is required')
    .positive('Estimated Price Lower Range must be a positive number')
    .typeError('Invalid Lower Range amount'),
  estimatedPriceHigherRange: Yup.number()
    .required('Estimated Price Higher Range is required')
    .positive('Estimated Price Higher Range must be a positive number')
    .typeError('Invalid Higher Range amount'),
  estimatedRent: Yup.number()
    .required('Estimated Weekly Rent is required')
    .positive('Estimated Weekly Rent must be a positive number')
    .typeError('Invalid Estimated Weekly Rent amount'),
  estimatedRentLowerRange: Yup.number()
    .required('Estimated Weekly Rent Lower Range is required')
    .positive('Estimated Weekly Rent Lower Range must be a positive number')
    .typeError('Invalid Lower Range amount'),
  estimatedRentHigherRange: Yup.number()
    .required('Estimated Weekly Rent Higher Range is required')
    .positive('Estimated Weekly Rent Higher Range must be a positive number')
    .typeError('Invalid Higher Range amount'),
});

const AvEstimates = () => {
  const { isMobileViewport } = useViewport();
  const dispatch = useDispatch();
  const location = useLocation();

  const formikRef: any = useRef();

  const user = useSelector(selectUser);
  const estimates = useSelector(selectEstimates);

  const avmEnablePdfReport = useSelector(selectAvmEnablePdfReport);
  const avmFromCoreLogic = useSelector(selectAvmFromCoreLogic);

  const [isDisclaimerModalActive, setIsDisclaimerModalActive] = useState(false);
  const [disclaimerModalType, setDisclaimerModalType] =
    useState<DISCLAIMER_MODAL_TYPES>('Confidence');

  const queryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  const convertDollarStringToNumber = (dollarString: string) => {
    const cleanedString = dollarString.replace(/[^0-9.]/g, '');
    const floatValue = parseFloat(cleanedString);

    return floatValue;
  };

  const formatDate = (timestamp?: string) => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleString(undefined, options);
  };

  const [isFormLoading, setIsFormLoading] = useState(false);

  // Function to handle CoreLogic silent login
  const handleSilentLogic = async () => {
    if (user) {
      const { token, sessionId } = user;
      setIsFormLoading(true);

      try {
        // Fetch CoreLogic user details
        const results = await dispatch(
          getCoreLogicUser({ token, sessionId }),
        ).unwrap();

        if (results && results.coreLogicClientId) {
          const { coreLogicClientId, silentLoggedIn, oAuth2Url, redirectUrl } =
            results;

          // If not silently logged in, redirect to CoreLogic OAuth2 URL
          if (silentLoggedIn === false) {
            window.location.href = `${oAuth2Url}?response_type=code&client_id=${coreLogicClientId}&scope=openid&redirect_uri=${redirectUrl}`;
          }
        }

        setIsFormLoading(false);
      } catch (error) {
        setIsFormLoading(false);
      }
    }
  };

  useEffect(() => {
    // Redirect to CoreLogic for auth only on the first visit.
    // Avoid redirect if the 'code' is already present in the query parameters
    if (!queryString?.code) {
      handleSilentLogic();
    }
  }, [avmFromCoreLogic, queryString]);

  const updateEstimates = async (params: any) => {
    if (user && estimates?.propertyId) {
      setIsFormLoading(true);
      const { token, userId, sessionId } = user;

      await dispatch(
        updateAvEstimates({
          token: token,
          data: {
            userId: userId,
            sessionId: sessionId,
            propertyId: estimates?.propertyId,
            ...params,
          },
        }),
      );

      notification.success({
        message: 'Success!',
        description: 'AV Estimates Updated',
      });

      setIsFormLoading(false);
    }
  };

  const handleDownload = async (url) => {
    try {
      const link = document.createElement('a');
      link.href = url;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'PDF download failed.',
      });
    }
  };

  const getPdf = async () => {
    if (user && estimates?.propertyId) {
      try {
        setIsFormLoading(true);

        const { token, userId, sessionId } = user;

        const response = await dispatch(
          getPdfReport({
            token: token,
            data: {
              userId: userId,
              sessionId: sessionId,
              propertyId: estimates?.propertyId.toString(),
            },
          }),
        ).unwrap();

        if (response?.reportUrl) {
          handleDownload(response.reportUrl);
        }

        setIsFormLoading(false);
      } catch (e) {
        setIsFormLoading(false);
      }
    }
  };

  const colorText = (level?: string) => {
    if (!level) {
      return '#eee';
    }

    switch (level.toLowerCase()) {
      case 'high':
        return '#20c997';
      case 'medium':
        return '#ff9800';
      case 'low':
        return '#dc3545';
      default:
        return '#000';
    }
  };

  const handlePdfClick = () => {
    getPdf();
  };

  const formatNumberWithCommas = (number?: string | number) => {
    return number !== null && number !== undefined
      ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '';
  };

  const removeCommas = (formattedNumber?: string | number) => {
    return formattedNumber !== null && formattedNumber !== undefined
      ? formattedNumber.toString().replace(/,/g, '')
      : '';
  };

  const handleDisclaimerModalClose = () => {
    setIsDisclaimerModalActive(false);
  };

  useEffect(() => {
    if (estimates && formikRef?.current) {
      formikRef.current.resetForm();
    }
  }, [estimates]);

  const renderInput = (field: any, form: any, placeholder: string) => {
    return (
      <InputNumber
        {...field}
        placeholder={placeholder}
        min={0}
        step={1}
        onChange={(value) => form.setFieldValue(field.name, value)}
        prefix="$"
        formatter={(value) => (value ? formatNumberWithCommas(value) : '')}
        parser={(value) => (value ? removeCommas(value) : '')}
      />
    );
  };

  const renderDownloadButton = () => {
    if (!avmEnablePdfReport) {
      return null;
    }

    return (
      <Button
        style={{
          height: '40px',
          marginRight: '8px',
        }}
        onClick={() => {
          handlePdfClick();
        }}
      >
        Download PDF
      </Button>
    );
  };

  const renderBadges = () => {
    const { propertyAttributes } = estimates;
    return (
      <div className="l-property-search__badge-container l-property-search__badge-container--light">
        {propertyAttributes?.propertyType && (
          <div className="l-property-search__badge">
            {propertyAttributes.propertyType}
          </div>
        )}
        {propertyAttributes?.landSize && (
          <div className="l-property-search__badge">
            {propertyAttributes.landSize}
          </div>
        )}
        {propertyAttributes?.beds && (
          <div className="l-property-search__badge">
            <Bed />
            {propertyAttributes.beds}
          </div>
        )}
        {propertyAttributes?.baths && (
          <div className="l-property-search__badge">
            <Bath />
            {propertyAttributes.baths}
          </div>
        )}
        {propertyAttributes?.carSpaces && (
          <div className="l-property-search__badge">
            <Car />
            {propertyAttributes.carSpaces}
          </div>
        )}
      </div>
    );
  };

  const handleDisclaimerModalOpen = useCallback(
    (type: DISCLAIMER_MODAL_TYPES) => {
      setDisclaimerModalType(type);
      setIsDisclaimerModalActive(true);
    },
    [],
  );

  const consumerDisclaimerTrigger = useCallback(
    (type: DISCLAIMER_MODAL_TYPES) => {
      return (
        <Button
          className="consumer-disclaier-trigger"
          type="link"
          onClick={() => {
            handleDisclaimerModalOpen(type);
          }}
        >
          <InfoCircleFilled />
        </Button>
      );
    },
    [],
  );

  if (!estimates) {
    return null;
  }

  return (
    <section className="l-property-search__estimates">
      {isFormLoading && <FullPageLoader />}
      {isDisclaimerModalActive && (
        <DisclaimerModal
          closeHandler={handleDisclaimerModalClose}
          stateCode={estimates.stateCode}
          type={disclaimerModalType}
        />
      )}

      <div className="l-property-search__estimates-body">
        <div className="l-property-search__estimates-details">
          <div className="l-property-search__estimates-details-flex">
            <EnvironmentFilled
              style={{
                opacity: 0.7,
                fontSize: '32px',
                color: '#00b2a3',
                marginRight: '6px',
              }}
            />
            <div>
              <h3>{estimates?.propertyAddress}</h3>
              <p>
                Prepared on <strong>{formatDate(estimates?.createDate)}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="l-property-search__estimates-form">
          <Formik
            innerRef={formikRef}
            initialValues={{
              estimatedPrice: convertDollarStringToNumber(
                estimates?.estimatedPrice,
              ),
              estimatedPriceLowerRange: convertDollarStringToNumber(
                estimates?.estimatedPriceRange?.lowerRange,
              ),
              estimatedPriceHigherRange: convertDollarStringToNumber(
                estimates?.estimatedPriceRange?.higherRange,
              ),
              estimatedRent: convertDollarStringToNumber(
                estimates?.estimatedWeeklyRent,
              ),
              estimatedRentLowerRange: convertDollarStringToNumber(
                estimates?.estimatedWeeklyRentRange?.lowerRange,
              ),
              estimatedRentHigherRange: convertDollarStringToNumber(
                estimates?.estimatedWeeklyRentRange?.higherRange,
              ),
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              updateEstimates(values);
            }}
          >
            {({ handleSubmit }) => {
              const renderPriceHeading = () => {
                return (
                  <Col xs={24} md={12}>
                    <div className="l-property-search__field">
                      <label htmlFor="-">
                        Price Estimation Confidence
                        {consumerDisclaimerTrigger('Confidence')}
                      </label>

                      <h6
                        className="l-property-search__estimates-confidence"
                        style={{
                          color: colorText(estimates?.priceConfidence),
                        }}
                      >
                        {estimates?.priceConfidence}
                      </h6>
                    </div>
                    {!isMobileViewport ? (
                      <Divider style={{ margin: '12px 0' }} />
                    ) : (
                      <></>
                    )}
                  </Col>
                );
              };

              const renderRentHeading = () => {
                return (
                  <Col xs={24} md={12}>
                    <div className="l-property-search__field">
                      <label htmlFor="-">
                        Rent Estimation Confidence{' '}
                        {consumerDisclaimerTrigger('Confidence')}
                      </label>
                      <h6
                        className="l-property-search__estimates-confidence"
                        style={{
                          color: colorText(estimates?.rentConfidence),
                        }}
                      >
                        {estimates?.rentConfidence}
                      </h6>
                    </div>
                    {!isMobileViewport ? (
                      <Divider style={{ margin: '12px 0' }} />
                    ) : (
                      <></>
                    )}
                  </Col>
                );
              };

              const renderPriceForm = () => {
                return (
                  <Col xs={24} md={12}>
                    <div className="l-property-search__field">
                      <label htmlFor="estimatedPrice">
                        Estimated Price {consumerDisclaimerTrigger('Estimate')}
                      </label>
                      <div>
                        <Field
                          name="estimatedPrice"
                          render={({ field, form }) => (
                            <>{renderInput(field, form, '1,000,000')}</>
                          )}
                        />
                        <ErrorMessage
                          name="estimatedPrice"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="l-property-search__field">
                      <label htmlFor="estimatedPriceLowerRange">
                        Estimated Price Range
                      </label>
                      <div className="l-property-search__estimates-half-fields">
                        <div className="h-field">
                          <Field
                            name="estimatedPriceLowerRange"
                            render={({ field, form }) => (
                              <>{renderInput(field, form, '1,000,000')}</>
                            )}
                          />
                          <ErrorMessage
                            name="estimatedPriceLowerRange"
                            component="div"
                            className="error"
                          />
                        </div>
                        <span className="h-field">
                          <ArrowRightOutlined />
                        </span>
                        <div className="h-field">
                          <Field
                            name="estimatedPriceHigherRange"
                            render={({ field, form }) => (
                              <>{renderInput(field, form, '1,000,000')}</>
                            )}
                          />
                          <ErrorMessage
                            name="estimatedPriceHigherRange"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    {isMobileViewport && (
                      <Divider style={{ margin: '30px 0 18px' }} />
                    )}
                  </Col>
                );
              };
              const renderRentForm = () => {
                return (
                  <Col xs={24} md={12}>
                    <div className="l-property-search__field">
                      <label htmlFor="estimatedRent">
                        Estimated Weekly Rent{' '}
                        {consumerDisclaimerTrigger('Estimate')}
                      </label>
                      <div>
                        <Field
                          name="estimatedRent"
                          render={({ field, form }) => (
                            <>{renderInput(field, form, '500')}</>
                          )}
                        />
                        <ErrorMessage
                          name="estimatedRent"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>

                    <div className="l-property-search__field">
                      <label htmlFor="estimatedRentLowerRange">
                        Estimated Weekly Rent Range
                      </label>
                      <div className="l-property-search__estimates-half-fields">
                        <div className="h-field">
                          <Field
                            name="estimatedRentLowerRange"
                            render={({ field, form }) => (
                              <>{renderInput(field, form, '500')}</>
                            )}
                          />
                          <ErrorMessage
                            name="estimatedRentLowerRange"
                            component="div"
                            className="error"
                          />
                        </div>
                        <span className="h-field">
                          <ArrowRightOutlined />
                        </span>
                        <div className="h-field">
                          <Field
                            name="estimatedRentHigherRange"
                            render={({ field, form }) => (
                              <>{renderInput(field, form, '500')}</>
                            )}
                          />
                          <ErrorMessage
                            name="estimatedRentHigherRange"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              };
              return (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={[42, 0]}>
                    {isMobileViewport ? (
                      <>
                        {renderPriceHeading()}
                        {renderPriceForm()}
                      </>
                    ) : (
                      <>
                        {renderPriceHeading()}
                        {renderRentHeading()}
                      </>
                    )}
                  </Row>
                  <Row gutter={[42, 0]}>
                    {isMobileViewport ? (
                      <>
                        {renderRentHeading()}
                        {renderRentForm()}
                      </>
                    ) : (
                      <>
                        {renderPriceForm()}
                        {renderRentForm()}
                      </>
                    )}
                  </Row>
                  <Row gutter={[42, 0]}>
                    <Col xs={24} md={12}>
                      <Divider style={{ margin: '24px 0 12px' }} />
                      <div className="l-property-search__field">
                        <label htmlFor="-">Property Attributes</label>
                        {renderBadges()}
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <Divider
                        style={
                          isMobileViewport
                            ? { margin: '9px 0 12px' }
                            : { margin: '24px 0 12px' }
                        }
                      />
                      <div className="l-property-search__field">
                        <label htmlFor="-">Estimated Gross Rental Yield</label>
                        <strong className=" h-emp">
                          {estimates?.estimatedGrossRentalYield}
                        </strong>
                      </div>
                    </Col>
                  </Row>
                  <div className="l-property-search__avm-btn-group">
                    <div>
                      {avmFromCoreLogic && (
                        <Button
                          style={{
                            fontSize: '15px',
                            padding: 0,
                            color: '#00b2a3',
                          }}
                          type="link"
                          onClick={() => {
                            handleDisclaimerModalOpen('State');
                          }}
                        >
                          <InfoCircleFilled />
                          <span style={{ marginLeft: '8px' }}>
                            Source: CoreLogic
                          </span>
                        </Button>
                      )}
                    </div>
                    <div>
                      {renderDownloadButton()}

                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ height: '40px', width: '100px' }}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default AvEstimates;
