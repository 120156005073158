import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  classNames?: string;
  message?: string;
  noSpin?: boolean;
};

function FullPageLoader({ classNames, message, noSpin }: Props): JSX.Element {
  return (
    <div className={`full-page-loader ${classNames || ''}`}>
      {!noSpin && (
        <Spin
          indicator={<Loading3QuartersOutlined style={{ fontSize: 28 }} spin />}
        />
      )}
      {message && <p className="full-page-loader__message">{message}</p>}
    </div>
  );
}

// PropTypes
FullPageLoader.propTypes = {
  classNames: PropTypes.string,
};

export default FullPageLoader;
