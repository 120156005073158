import { Button, Modal, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectProperty } from '../../property_search/redux/selectors';

type PreviewModalProps = {
  closeHandler: () => void;
};

const PreviewModal = ({ closeHandler }: PreviewModalProps) => {
  const property = useSelector(selectProperty);
  const apexReportUrl = property?.apexReportUrl;

  const [copied, setCopied] = useState(false);

  const { isMobileViewport } = useViewport();

  const handleOpenClick = () => {
    window.open(apexReportUrl, '_blank');
  };

  const handleCopyClick = () => {
    const tempInput = document.createElement('input');

    tempInput.value = apexReportUrl;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Modal
      title={'Preview'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={isMobileViewport ? '100%' : '80%'}
      styles={{
        body: {
          padding: '0',
        },
      }}
    >
      <div className="l-property-search__preview-modal">
        <div className="l-property-search__preview-btn-group">
          <Button type="primary" onClick={handleOpenClick}>
            Open In New Tab
          </Button>
          {copied ? (
            <Tooltip title={'Copied'}>
              <Button type="primary" onClick={handleCopyClick}>
                Copy Link
              </Button>
            </Tooltip>
          ) : (
            <Button type="primary" onClick={handleCopyClick}>
              Copy Link
            </Button>
          )}
        </div>
        {apexReportUrl && (
          <iframe
            className="l-property-search__preview-iframe"
            src={`${apexReportUrl}/htmlview`}
            title="APEX Report"
          />
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
