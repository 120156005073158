import { Table } from 'antd';
import React from 'react';

const ConsumerFieldDisclaimer = () => {
  return (
    <p className="corelogic-disc-tooltip">
      The Confidence Indicators are defined as below:
      <br />
      <Table
        bordered
        pagination={false}
        style={{ marginTop: 10 }}
        columns={[
          {
            title: '',
            dataIndex: 'key',
            key: 'key',
          },
          {
            title: '',
            dataIndex: 'value',
            key: 'value',
          },
        ]}
        dataSource={[
          {
            key: 'High',
            value: `High confidence typically a result of a greater number of available
            comparable properties and/or a lesser degree of variability between
            the subject and comparable properties and/or high availability of
            comparable property data in the area.`,
          },
          {
            key: 'Medium',
            value: `Medium confidence typically a result of a medium degree of available
            comparable properties and variability between the subject and
            comparable properties in the area and/or medium availability of
            comparable property data in the area.`,
          },
          {
            key: 'Low',
            value: ` Low confidence typically a results of fewer available comparable
            properties and/or a high degree of variability between the subject
            and comparable properties in the area and/or low availability of
            comparable property data in the area. This AVM is not reliable and
            should only be used with caution.`,
          },
        ]}
      />
    </p>
  );
};

export default ConsumerFieldDisclaimer;
