import React from 'react';

export const renderStateDisclaimer = (code: string) => {
  switch (code) {
    case 'QLD':
      return (
        <>
          <strong>Queensland Data</strong>
          <br />
        </>
      );
    case 'SA':
      return (
        <>
          <strong>South Australian Data</strong>
          <br />
        </>
      );
    case 'NSW':
      return (
        <>
          <strong>New South Wales Data</strong>
          <br />
        </>
      );
    case 'VIC':
      return (
        <>
          <strong>Victorian Data</strong>
          <br />
        </>
      );
    case 'WA':
      return (
        <>
          <strong>Western Australian Data</strong>
          <br />
        </>
      );
    case 'ACT':
      return (
        <>
          <strong>Australian Capital Territory Data</strong>
          <br />
        </>
      );
    case 'TAS':
      return (
        <>
          <strong>Tasmanian Data</strong>
          <br />
        </>
      );
  }
};

type MainSourceDisclaimerProps = {
  stateCode?: string;
};

const MainSourceDisclaimer = ({ stateCode }: MainSourceDisclaimerProps) => {
  return (
    <>
      <p>
        © Copyright 2016. RP Data Pty Ltd trading as CoreLogic Asia Pacific (
        <strong>CoreLogic</strong>) and its licensors are the sole and exclusive
        owners of all rights, title and interest (including intellectual
        property rights) subsisting in this publication, including any data,
        analytics, statistics and other information contained in this
        publication (Data). All rights reserved.
      </p>
      <p>
        Whilst <strong>CoreLogic</strong> uses commercially reasonable efforts
        to ensure the Data and information in this publication is current,{' '}
        <strong>CoreLogic</strong> does not warrant the accuracy, currency or
        completeness of the Data and commentary contained in this publication
        and to the full extent not prohibited by law excludes all loss or damage
        howsoever arising (including through negligence) in connection with the
        Data and commentary contained in this publication.
      </p>
      <p>
        <strong>Australian Copyright Notice</strong>
        <br />
        When displaying Australian sourced Product Data from CoreLogic, the
        following copyright notice must be displayed:
        <ul>
          <li>
            alongside any data that is provided, or that uses CoreLogic in its
            creation
          </li>
          <li>
            in the event that a property report is compiled with the use of
            CoreLogic Product Data, alongside any commentary and content; and
          </li>
          <li>
            if made available online, by cross reference or link in close
            proximity to the data to the page upon which the disclaimer is
            located, on the same page in close proximity to the Product Data.
          </li>
        </ul>
      </p>
      <p>
        <strong>CoreLogic General Disclaimer - Data and Reports</strong> <br />
        The supply of any Product Data for generation of reports is made subject
        to the following disclaimer. Any creation of reports utilising Product
        Data from Australia and New Zealand must include the following
        disclaimer. <br />
        The supply of any Product Data is made subject to the following
        disclaimer. Any display by you of any Product Data provided by CoreLogic
        (in any form) must display the following disclaimer either:
        <ul>
          <li>on the same page on which the Product Data appears; or</li>
          <li>
            at the bottom of the report in which the Product Data appears; or
          </li>
          <li>
            if made available online, a link or cross reference to the
            Disclaimers must be displayed in close proximity to the Product
            Data.
          </li>
        </ul>
      </p>
      <p>
        <strong>AVM Estimate</strong>
        <br />
        The supply of any AVM Estimate is made subject to the following
        disclaimer. Any display by you of an AVM Estimate provided by CoreLogic
        (in any form) must display the following disclaimer either:
        <ul>
          <li>on the same page on which the AVM Estimate appears; or</li>
          <li>
            if made available online, by hover box cross reference or link to
            the page upon which the disclaimer is located, on the same page in
            close proximity to the AVM Estimate.
          </li>
        </ul>
        For the purposes of the above, the references to the AVM Estimate
        disclaimer may be footnoted (if prepared as a document) or by use of a
        hover box or similar if appearing on an online application (provided
        that the relevant display is also incorporated into the terms for
        supply).
      </p>
    </>
  );
};

export default MainSourceDisclaimer;
