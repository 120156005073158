import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../authentication/redux/selectors';
import ContentWrapper from './content_wrapper/ContentWrapper';
import Header from './header/Header';

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const user = useSelector(selectUser);

  if (!user) {
    return children;
  }

  return (
    <Layout className="l-layout">
      <Header />
      <Layout>
        <ContentWrapper>{children}</ContentWrapper>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
