import { Layout } from 'antd';
import React, { ReactNode } from 'react';

const { Content } = Layout;

type Props = {
  children: ReactNode;
};

const ContentWrapper = ({ children }: Props) => {
  return (
    <Content style={{ backgroundColor: '#f2f3f4' }}>
      <div className="l-main">{children}</div>
    </Content>
  );
};

export default ContentWrapper;
