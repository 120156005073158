import {
  EnvironmentFilled,
  FormOutlined,
  HarmonyOSOutlined,
  PictureOutlined,
  StarFilled,
} from '@ant-design/icons';
import { Divider, Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectProperty } from '../../property_search/redux/selectors';
import ApexReportForm from '../report_form/ReportForm';

const PropertyDetails = ({ isLookupLoading }: { isLookupLoading: boolean }) => {
  const property = useSelector(selectProperty);

  const [isImageError, setIsImageError] = useState(false);

  const computeScoreColorValue = (number = 0) => {
    if (!number) {
      return '#ccc';
    }

    if (number < 1) {
      number = 1;
    } else if (number > 10) {
      number = 10;
    }

    const red = Math.round(255);
    let green: number;

    if (number >= 9) {
      green = Math.round(169 + 8 * 7);
    } else {
      green = Math.round(169 + 8 * (number - 1));
    }

    const blue = Math.round(64);

    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    return `#${redHex}${greenHex}${blueHex}`;
  };

  const handleImageError = () => {
    setIsImageError(true);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span style={{ padding: '0 20px' }}>
          <FormOutlined style={{ marginRight: '5px' }} /> Inputs
        </span>
      ),
      children: <ApexReportForm />,
    },
    {
      key: '2',
      label: (
        <span style={{ padding: '0 20px' }}>
          <HarmonyOSOutlined style={{ marginRight: '7px' }} />
          Client Details
        </span>
      ),
      children: (
        <ul className="l-property-search__input-details">
          <li className="l-property-search__input-detail-item">
            <span>Purchasing Entity</span>
            <div className="l-property-search__badge h-margin--zero h-color--blue">
              {property?.clientDetails?.entityType ?? '-'}
            </div>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Focus</span>
            <div className="l-property-search__badge h-margin--zero h-color--blue">
              {property?.clientDetails?.topFocus ?? '-'}
            </div>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>LVR</span>
            <strong>{property?.clientDetails?.loanToValueRatio ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Load Product</span>
            <strong>{property?.clientDetails?.repayments ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Interest Rate</span>
            <strong>{property?.clientDetails?.interestRate ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Loan Pre-approval</span>
            <strong>{property?.clientDetails?.loanPreApproval ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span style={{ color: '#00b2a3' }}>Dashdot Fee</span>
            <strong>{property?.clientDetails?.totalFee ?? '-'}</strong>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    setIsImageError(false);
  }, [property]);

  return (
    <section className="l-property-search__property">
      {isLookupLoading && <FullPageLoader noSpin />}
      <div className="l-property-search__property-info">
        <div className="l-property-search__property-photo">
          {property.photoUrl && !isImageError && (
            // NOSONAR
            <img
              src={property.photoUrl}
              alt={property.propertyAddress}
              onError={handleImageError}
            />
          )}
          {(!property.photoUrl || isImageError) && (
            <span
              style={{
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PictureOutlined
                style={{ marginRight: '8px', fontSize: '20px' }}
              />
              No photo available
            </span>
          )}
        </div>
        <div className="l-property-search__details">
          <div>
            <h3>AUD {property.listingPrice}</h3>
            <p>
              <EnvironmentFilled
                style={{
                  opacity: 0.7,
                  fontSize: '20px',
                  color: '#ff8920',
                }}
              />
              {property.propertyAddress}
            </p>
            <div className="l-property-search__badge-container">
              {property.propertyType && (
                <div className="l-property-search__badge">
                  {property.propertyType}
                </div>
              )}
              {property.state && (
                <div className="l-property-search__badge">{property.state}</div>
              )}
              {property.landSize && (
                <div className="l-property-search__badge">
                  {property.landSize}
                </div>
              )}
            </div>
          </div>
          <div className="l-property-search__score-container">
            <div className="l-property-search__score">
              <span>
                Lifestyle
                <br />
                Score
              </span>
              <div className="l-property-search__score-value">
                <StarFilled
                  style={{
                    color: computeScoreColorValue(property.lifestyleScore),
                    fontSize: '56px',
                  }}
                />
                <span>
                  {property.lifestyleScore ? property.lifestyleScore : '-'}
                </span>
              </div>
            </div>
            <div className="l-property-search__score">
              <span>
                Convenience
                <br />
                Score
              </span>
              <div className="l-property-search__score-value">
                <StarFilled
                  style={{
                    color: computeScoreColorValue(property.convenienceScore),
                    fontSize: '56px',
                  }}
                />
                <span>
                  {property.convenienceScore ? property.convenienceScore : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <ul className="l-property-search__other-details">
          {property?.lgaDetails?.lgaName && (
            <li className="l-property-search__other-detail-item">
              <div className="l-property-search__other-detail-label">LGA</div>
              <div className="l-property-search__other-detail-value">
                <strong>{property.lgaDetails.lgaName}</strong>
              </div>
            </li>
          )}
          {property?.vacancy && (
            <li className="l-property-search__other-detail-item">
              <div className="l-property-search__other-detail-label">
                Suburb Vacancy Rate
              </div>
              <div className="l-property-search__other-detail-value">
                <strong>{property.vacancy}</strong>
              </div>
            </li>
          )}
          {property?.publicHousing && (
            <li className="l-property-search__other-detail-item">
              <div className="l-property-search__other-detail-label">
                Public Housing %
              </div>
              <div className="l-property-search__other-detail-value">
                <strong>{property.publicHousing}</strong>
              </div>
            </li>
          )}
          {property?.renters && (
            <li className="l-property-search__other-detail-item">
              <div className="l-property-search__other-detail-label">
                Renters %
              </div>
              <div className="l-property-search__other-detail-value">
                <strong>{property.renters}</strong>
              </div>
            </li>
          )}
          {property?.appraisedRent && (
            <li className="l-property-search__other-detail-item">
              <div className="l-property-search__other-detail-label">
                Rent Per Week
              </div>
              <div className="l-property-search__other-detail-value">
                <strong>{property.appraisedRent}</strong>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="l-property-search__property-form">
        <h6
          style={{
            fontSize: '15px',
            fontWeight: 500,
            padding: '15px',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f7f8f9',
            borderRadius: '6px',
            justifyContent: 'center',
          }}
        >
          {property?.clientDetails?.clientName}
        </h6>
        <Tabs centered defaultActiveKey="1" items={items} />
      </div>
    </section>
  );
};

export default PropertyDetails;
