import './styles/styles.scss';

import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Layout from './features/layout/Layout';
import Startup from './features/startup/Startup';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const Root = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b2a3',
          fontFamily: 'Figtree, sans-serif',
        },
      }}
    >
      <Layout>
        <Startup />
      </Layout>
    </ConfigProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
);

reportWebVitals();
